import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import LectureCard from '../components/lecture-card'

import styles from '../styles/course-content-display.module.scss'

export default function FrontEndFundementals ({ data, location }) {
  const { allMarkdownRemark } = data

  const lessons = allMarkdownRemark.edges.map(({ node }) =>
    <LectureCard
      key={node.id}
      slug={node.fields.slug}
      title={node.frontmatter.title}
      topics={node.frontmatter.topics}
      prereqs={node.frontmatter.prereqs ? node.frontmatter.prereqs : 'None'}
    />
  )

  return (
    <Layout
      centerContent
      pageTitle='MERN 11/22/2022 - 1/7/2023'
      location={location}
      crumbLabel='MERN'
    >
      <h1>MERN 11/22/2022 - 1/7/2023</h1>
      <main className={styles.main}>
        {lessons}
      </main>
    </Layout>
  )
}

// add pre-reqs to front matter
export const query = graphql`
query {
    allMarkdownRemark (
          filter: {frontmatter: {track: {eq: "React"},
          type: {eq: "homepage"}}}
            sort: {fields:  [frontmatter___week, frontmatter___day]}
        ){
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            track
            title
            week
            day
            type
            topics
            prereqs
          }
        }
      }
    }
  }
`
